import { GonProvider } from "../helpers/GonProviders";

const { isPartnerTraveler, apiParameterHelpers } = GonProvider;

const isDriver =
  GonProvider.apiParameterHelpers && GonProvider.apiParameterHelpers.author.type == "Driver" ? true : false;
const isDriverCompanyUser =
  GonProvider.apiParameterHelpers && GonProvider.apiParameterHelpers.author.type == "DriverCompanyUser" ? true : false;

const isDriverOrDriverCompanyUser = isDriver || isDriverCompanyUser;
const theme = {
	colors: {
		background: {
			white: "#ffffff",
			grey: "#f5f5f5",
		},
		base: {
			graphite: "#2d3b4e",
			graphiteDark: "#0f161e",
			white: "#ffffff",
			white75: "rgba(255, 255, 255, .75)",
			white5: "rgba(255, 255, 255, .5)",
		},
		greys: {
			grey6: "rgba(45, 59, 78, .6)",
			grey5: "rgba(45, 59, 78, .5)",
			grey4: "rgba(45, 59, 78, .4)",
			grey3: "rgba(45, 59, 78, .3)",
			grey2: "rgba(45, 59, 78, .2)",
			grey15: "rgba(45, 59, 78, .15)",
			grey1: "rgba(45, 59, 78, .1)",
			grey05: "rgba(45, 59, 78, .05)",
			grey06: "rgba(45, 59, 78, .06)"
		},
		cta: {
			primary: isDriverOrDriverCompanyUser ? "#6AD5E2" :  "#48D9A4",
			green: isDriverOrDriverCompanyUser ? "#6AD5E2" :  "#48D9A4",
			greenMedium: "#00CF95",
			greenDark:  isDriverOrDriverCompanyUser ? "#6AD5E2" : "#00C58A",
			greenHover: isDriverOrDriverCompanyUser ? "#75e1ef" :  "#8AE3BE",
			greenDisabled: isDriverOrDriverCompanyUser ? "#c5f9ff" : "#BAEED7",
			greenModern: "#50D8A5",
			mustard: "#F3A93A"
		},
		accent: {
			red: "#f27470",
			yellow: "#fbd97e",
			blue: "#f2f2f2"
		},
	},
	space: [0, 8, 12, 18, 24, 32, 40],
	typography: {
		"h1, h2, h3, h4": {
			lineHeight: "1.3",
		},
		h1: {
			fontSize: "36px",
		},
		h2: {
			fontSize: "30px",
		},
		h3: {
			fontSize: "24px",
		},
		h4: {
			fontSize: "20px",
		},
		".subheading": {
			fontSize: 14,
			fontWeight: 700,
			letterSpacing: 1.2,
			color: "rgba(45, 59, 78, .5)",
		},
		".subheading--sm": {
			fontSize: 12,
			fontWeight: 700,
			color: "rgba(45, 59, 78, .5)",
		},
		"p, .text, .text--sm, .text--xs": {
			fontSize: 16,
			lineHeight: 1.5,
			color: "#2d3b4e",
		},
		".text--sm": {
			fontSize: 14,
		},
		".text--xs": {
			fontSize: 12,
		},
	},
	breakpoints: [768, 992, 1200],
	borderRadius: {
		radius4: "4px"
	},
	tooltip: {
		backgroundColor: "rgba(15, 22, 30, 0.92) !important",
		padding: 16,
		fontSize: 14,
		fontWeight: 500,
		lineHeight: 1.29
	}
};


if (isPartnerTraveler) {
	theme.colors.cta = {
		...theme.colors.cta,
		...GonProvider.coBrandingThemeProperties,
		primary: GonProvider.coBrandingThemeProperties.backgroundColor,
		green: GonProvider.coBrandingThemeProperties.mainColor,
		greenHover: `${GonProvider.coBrandingThemeProperties.mainColor}e0`,
		greenDisabled: `${GonProvider.coBrandingThemeProperties.mainColor}59`,
	};
	theme.colors.base = {
		...theme.colors.base,
		...GonProvider.coBrandingThemeProperties,

		backgroundColor: GonProvider.coBrandingThemeProperties.backgroundColor,
	};
	theme.tooltip = {
		...theme.tooltip,
		...GonProvider.coBrandingThemeProperties,
		backgroundColor: `${GonProvider.coBrandingThemeProperties.backgroundColor}!important`,
	};
}

if (isPartnerTraveler) {
	theme.colors.cta = {
		...theme.colors.cta,
		...GonProvider.coBrandingThemeProperties,
		green: GonProvider.coBrandingThemeProperties.mainColor,
		greenMedium: GonProvider.coBrandingThemeProperties.mainMediumColor || GonProvider.coBrandingThemeProperties.mainColor,
		greenDark: GonProvider.coBrandingThemeProperties.mainDarkColor || GonProvider.coBrandingThemeProperties.mainColor,
		greenHover: GonProvider.coBrandingThemeProperties.hoverColor || GonProvider.coBrandingThemeProperties.mainColor,
		greenDisabled: GonProvider.coBrandingThemeProperties.disabledColor || GonProvider.coBrandingThemeProperties.mainColor,
	};
}

export default theme;
