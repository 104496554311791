/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import greenLandingIcon from "assets/images/landing-pages/greenLandingIcon";
import greenLockIcon from "assets/images/landing-pages/greenLockIcon";
import greenBellIcon from "assets/images/landing-pages/greenBellIcon";
import greenTimeIcon from "assets/images/landing-pages/greenTimeIcon";
import whiteHeartIcon from "assets/images/landing-pages/whiteHeartIcon";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Text1 } from "../../themes/typography";
import { WhiteSquares, Title, GreenSeparator, FlexContainer, Container, LeftColumn } from "./styledComponents";
import { GonProvider } from "../../helpers/GonProviders";

const { current_property: { main_bg_image: bgImage, property_url: propertyUrl },
    translations: { driver_can_wait, forget_the_cash, skip_taxi_lines, special_tips, go_to_property_link, more_cool_benefits_title } } = GonProvider;

const showWebsiteBanner = propertyUrl && propertyUrl !== "http://" && propertyUrl !== null && propertyUrl !== "";

const hotelFeatures = [
    {
        icon: greenLandingIcon,
        text: driver_can_wait
    },
    {
        icon: greenLockIcon,
        text: forget_the_cash
    },
    {
        icon: greenTimeIcon,
        text: skip_taxi_lines
    },
    {
        icon: whiteHeartIcon,
        text: special_tips
    }
]

const bnbFeatures = [
    {
        icon: greenLandingIcon,
        text: driver_can_wait
    },
    {
        icon: greenBellIcon,
        text: forget_the_cash
    },
    {
        icon: greenTimeIcon,
        text: skip_taxi_lines
    },
    {
        icon: whiteHeartIcon,
        text: special_tips
    }
];

const FeaturesContainer = styled(FlexContainer)`
    flex-wrap: wrap;
`;

const Icon = styled(FlexContainer)(({ color = "rgba(72,217,164,0.1)" }) => (`
    max-width: 64px;
    background-color: ${color};
    border-radius: 50px;
    flex:0 0 64px;
    height: 64px;
    margin-bottom: 10px;

    @media (max-width: 580px){
        margin: 0 24px 10px auto;
    }
`));

const Text = styled(Text1)(({ color }) => (`
    font-size: 16px;
    font-weight: 500;
    color: ${color}
`));

const OpacityContainer = styled(FlexContainer)`
    height: 100%;
    width: 100%;
    border-radius: 8px;
    background-color: rgba(45,59,78,0.4);
`;

const WhiteButton = styled.a`
    background-image: url(${bgImage});
    background-color: rgba(45,59,78,0.4);
    width: 220px;
    background-position: center;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: blur(5px);
    background-size: 600px;
    z-index: 10;

    @media (max-width: 468px){
        background-size: 400px;
    }
`;

const Background = styled(FlexContainer)`
    background-image:url(${bgImage});
    z-index: 2;
    height: 157px;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 5px 20px 0 rgba(45,59,78,0.09);
    background-size: cover;
    background-position: center;
`;

const ButtonContainer = styled.div`
    position: relative;
`;

const LinkText = styled.a`
    position: absolute;
    z-index: 50;
    left:0;
    color: #FFFFFF;
    font-size: 16px;
    width: 100%;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 14px 0;
    font-family: MuseoSans;
    text-align:center;

    :hover {
        color: #ffff;
    }
`;

const MobileBenefitsContainer = styled.div`
    margin: 35px 0;
    @media(max-width:991px){
      display:flex;
    }
`;

const DesktopBenefits = ({ features }) => (
    features.map(({ icon, text }, i) => (
        <WhiteSquares key={text} bgGradient={i === 3 && "#50D8CB, #48D9A4"} css={{ textAlign: "left",
                marginRight: i%2===0 && "30px" }}>
            <Icon align="center" justify="center" color={i === 3 ? "#FFF" : "rgba(72,217,164,0.1)"}><LazyLoadImage loading="lazy" src={icon} alt={text} /></Icon>
            <Text color={i === 3 ? "#FFF" : "#2D3B4E"}>{text}</Text>
        </WhiteSquares>
    ))
)

const MobileBenefits = ({ features }) => (
    <WhiteSquares >
        {features.map(({ icon, text }, i) => (
            <MobileBenefitsContainer key={text}>
                <Icon align="center" justify="center" color="rgba(72,217,164,0.1)"><LazyLoadImage loading="lazy" src={icon} alt={text} /></Icon>
                <Text color="#2D3B4E" css={{ textAlign: "left" }}>{text}</Text>
            </MobileBenefitsContainer>
        ))}
    </WhiteSquares>
)

const MoreBenefits = ({ type="bnb" }) => {
    const availablefeatures = type==="bnb" ? bnbFeatures : hotelFeatures;

    return (
    <Container>
        <LeftColumn>
            <Title>{more_cool_benefits_title.split(" ").map(item=>item.substring(0,1).toUpperCase()+item.substring(1)).join(" ")}</Title>
            <GreenSeparator />
            <FeaturesContainer justify="flex-start" align="center">
                {window.innerWidth > 580 ? <DesktopBenefits features={availablefeatures}/> : <MobileBenefits features={availablefeatures}/>}
            </FeaturesContainer>
            {/* {showWebsiteBanner && */}
                <Background align="center" justify="center">
                    <OpacityContainer align="center" justify="center">
                        <ButtonContainer>
                            <LinkText href={propertyUrl} target="_blank">{go_to_property_link}</LinkText>
                            <WhiteButton href={propertyUrl} target="_blank">
                                <OpacityContainer/>
                            </WhiteButton>
                        </ButtonContainer>
                    </OpacityContainer>
                </Background>
            {/* } */}
        </LeftColumn>
    </Container>
);
    }


export default MoreBenefits;
