/* eslint-disable max-statements */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Component } from "react";
import styled from "@emotion/styled";
import transfersbyWelcomePickupsLogo from "assets/images/landing-pages/transfersbyWelcomePickupsLogo";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getCurrentLanguage, addScript } from "./helpers";
import { TransparentLink } from "./styledComponents";
import { GonProvider } from "../../helpers/GonProviders";

const { dropoff, checkin_date, current_property: { id, hide_lp_powered_by_header, quote_form_host: quote, branding_theme: { main_color: mainColor } } } = GonProvider;
const diffTop = window.innerHeight > 700 ? "150px" : "100px";
const isPreview = window.location?.pathname?.split("/")?.pop() === "preview";

const WidgetBackground = styled.div(({ scroll, scrolledTop, rightOffset,type }) => (`
	position: ${scroll ? "fixed" : "absolute"};
	left: ${scroll ? (rightOffset-360)+"px" : "auto"};
  right:0px;
	top: ${scrolledTop};
	box-shadow: 0 5px 20px 0 rgba(45,59,78,0.08);
	border-radius: 15px;
	background: #fff;
	max-width: 360px;
	z-index: 100;
  bottom:0;
  transform:translateY(-50%);
  bottom:0;
  height:200px;
  @media(max-width:1200px){

  }
  @media(max-width:1024px){
    right:0px;
  }
	@media (max-width: 991px){
    left:0;
    right:0;
    margin-top:${type=="hotel" ? "-80px" : "-60px"}!important;
    position:relative;
    margin: auto;
    height: auto;
    transform: none;
    margin-left:auto;
    margin-right:auto;
    height:auto;
    z-index:9;
    }
@media(max-width:991px){
  z-index:8;
}
	@media (max-width: 768px){
    margin-top:-30px!important;


  }

	
`));

const WelcomePickupsLogo = styled(TransparentLink)`
	display: none;

	img {
		width: 190px;
		margin-bottom: 30px;
	}

	@media (max-width: 768px){
		display:block;
    }

`;

class WidgetContainer extends Component {

	constructor(props) {
		super(props);

		this.state = {
			scroll: false,
			scrolledTop: "50%",
      rightOffset:0
		}

	}

	componentDidMount() {
		const { type = "hotel" } = this.props;
		let props = {};
		const date = checkin_date || "";
		const { widget_tracking_info } = GonProvider;
		if (type === "bnbOrg") {
			props = {
				"no_card": "",
				"fluid": "0",
				"property_group_id": id,
				"to_location": "{\"city\":\"bnb organisation\"}",
				"locale": getCurrentLanguage(),
				"button_color": mainColor,
				"date": date
			}
		}
		else {
			const { current_property: { location_id: toLocationId } } = GonProvider;
			props = {
				"from_location_id": dropoff ? toLocationId : "",
				"to_location_id": dropoff ? "" : toLocationId,
				"no_card": "",
				"fluid": "0",
				"partner_id": id,
				"locale": getCurrentLanguage(),
				"button_color": mainColor,
				"quote_url": quote,
				"date": date
			}

		}

		props = { ...props, tracking_info: { ...widget_tracking_info } };

    if (window.innerWidth>991 && !isPreview){
      window.addEventListener("scroll", this.listenToScroll);
    }
		const widget = document.getElementById("welcomepickups");

		const widgetProps = document.createElement("script");
		widgetProps.type = "text/props";
		widgetProps.id = "widget-props"
		widgetProps.innerHTML = JSON.stringify(props);
		widget.appendChild(widgetProps);
		addScript(process.env.BOOKING_FORM_JS_SOURCE_URL);


    this.setState({rightOffset: document.getElementById('pid').getBoundingClientRect().left})
	}

	listenToScroll = () => {
		const fullHeight = Math.max(
			document.body.scrollHeight, document.documentElement.scrollHeight,
			document.body.offsetHeight, document.documentElement.offsetHeight,
			document.body.clientHeight, document.documentElement.clientHeight
		);

		if (window.pageYOffset >= 80) {
			this.setState({
				scroll: true,
				scrolledTop: "120px",
			});
		}
		if (window.pageYOffset < 80) {
			this.setState({
				scroll: false,
				scrolledTop: "50%",
			});
		}
		if (window.pageYOffset >= fullHeight - 850) {
			this.setState({
				scroll: false,
				scrolledTop: `${fullHeight - 800}px`
			});
		}
	}

	render() {
    const {type}=this.props;
		const { scroll, scrolledTop,rightOffset } = this.state;

		return (

			<WidgetBackground id="pid" rightOffset={rightOffset} scroll={scroll} type={type} scrolledTop={scrolledTop}>
      	<div id="welcomepickups" />
				{!hide_lp_powered_by_header &&
					<WelcomePickupsLogo mobileHide>
							<LazyLoadImage alt="welcome logo" src={transfersbyWelcomePickupsLogo} />
					</WelcomePickupsLogo>
				}
		   	</WidgetBackground>

		);
	}
};

export default WidgetContainer;
