import { styled } from "@material-ui/styles";
import { DialogContent, Checkbox } from "@material-ui/core";
import { Heading4 } from "../../../../themes/typography";
import theme from "../../../../themes/partnersDashboardTheme";

const PageHeader = styled(Heading4)({
	fontSize: "22px !important"
})

const ModalHeader = styled("div") ({
	padding: "15px 0",
	display: "flex",
	alignItems: "flex-end",
	"& img": {
		margin: "0 4px",
	},
});

const StyledContainer = styled("div")(() => ({
	borderRadius: "4px",
	padding: "24px 32px",
	backgroundColor: "#fff"
}));

const FlexContainer = styled("div")(({ align="normal", justify="initial", margin, gap=0, textAlign="left", height, rowgap, width, flexwrap, direction, style={} }) => ({
	display: "flex",
	alignItems: align,
	justifyContent: justify,
	margin,
	gap,
	textAlign,
	height,
	rowGap: rowgap,
	width,
	flexWrap: flexwrap,
	flexDirection: direction,
	...style
}));

// A flex container that shows buttons vertically on mobile devices
const ButtonsFlexContainer = styled(FlexContainer)({
	flexDirection: "column",

	[theme.breakpoints.up("sm")]: {
		flexDirection: "row"
	}
});

const SmDialogContent = styled(DialogContent)({
	width: "380px"
});

const DefaultAvatarBg = styled(FlexContainer)(({ size=38 })=>({
	width: `${size}px`,
  	flex:`0 0 ${size}px`,
	height: `${size}px`,
	backgroundColor: "#F4F5F6",
	borderRadius: "50px",
	padding: "10px"
}));

const GreenCheckbox = styled(Checkbox)({
    padding: "15px 3px",
    height: "25px",
    color: "#50D8A5 !important",
    "&:hover": {
        backgroundColor: "rgba(80, 216, 165, 0.08) !important"
    }
});

const DesktopItems= styled("div")({
	"@media (max-width:768px)": {
		display: "none !important",
	}
});

const MobileTransfers = styled("div")({
	display: "none",
	"@media (max-width:768px)": {
		display: "initial"
	}
});

const DesktopTransfers = styled("div")({
	display: "initial",
	"@media (max-width:768px)": {
		display: "none"
	},
	"&div": {
		"@media (max-width:768px)": {
			display: "none"
		}
	}
});



const Row = styled(FlexContainer)({
    margin: "8px 0",
    padding: "14px 17px",
    boxShadow: "0 1px 1px 0 rgba(45,59,78,0.06), 0 2px 2px 0 rgba(0,0,0,0.02)",
    color: "#2D3B4E",
    fontSize: "14px",
    justifyItems: "left",
    alignItems: "center",
    cursor: "pointer",
    backgroundColor: "#fff",
    border: "3px solid #fff",
    borderRight: "hidden",
    borderLeft: "hidden",
});


const TableRowContainer = styled("tr")(({ cursor="pointer", witherror }) => ({
	boxShadow: "0 1px 1px 0 rgba(45,59,78,0.06), 0 2px 2px 0 rgba(0,0,0,0.02)",
	color: "#2D3B4E",
	fontSize: "12px",
	justifyItems: "left",
	alignItems: "center",
	cursor,
	height: "54px",
	"& td": {
		backgroundColor: "#fff",
		padding: "6px 8px",
		borderTop: witherror && `1px solid ${theme.palette.accent.red}`,
		borderBottom: witherror && `1px solid ${theme.palette.accent.red}`
	},
	// borders do not work on the "tr" so we have to add them on each "td"
	"& td:nth-child(1)": {
		borderRadius: "4px 0 0 6px",
		borderLeft: witherror && `1px solid ${theme.palette.accent.red}`
	},
	"& td:last-child": {
		borderRadius: "0 4px 4px 0",
		borderRight: witherror && `1px solid ${theme.palette.accent.red}`
	}
}));

/* Table Head */
const HeadersContainer = styled("thead")({
	justifyItems: "left",
	background: "#eaebed"
});

const HeadersRow = styled("tr")({
	"& th:nth-child(1)":{
		borderRadius: "6px 0 0 6px",
		width: "77px",
		paddingLeft: "15px"
	}
});

const TableHeaders = styled("th")({
	alignItems: "center",
	height: "34px",
	border: "1px solid #eaebed",
	paddingRight: "30px",
	"& h6": {
	  fontSize: "11px",
	  letterSpacing: "0.92px",
	  textTransform: "uppercase"
	}
});

const WelcomeDialogContent = styled(DialogContent)(({ padding }) => ({
	fontFamily: "MuseoSans",
	padding
}));

const VQDialogContent = styled(DialogContent)(({ padding }) => ({
	fontFamily: "MuseoSans",
	padding,
	width: "100%",
	maxWidth: "460px",
	position: "relative"
}));

const VQContainer = styled(FlexContainer)({
	textAlign: "center",
	padding: "70px 0",
	width: "385px",

	"@media (max-width: 768px)": {
		width: "100%",
		height: "75vh",
		padding: "46px 0"
	}
});

const OnboardingSetup = styled("div")({
	display: "none",
	margin: "0 50px",

	[theme.breakpoints.up("md")]: {
		display: "flex",
    flexDirection: "column",
    justifyContent: "center"
	}
})

export {
	ModalHeader,
	StyledContainer,
	FlexContainer,
	ButtonsFlexContainer,
	SmDialogContent,
	DefaultAvatarBg,
	GreenCheckbox,
	DesktopItems,
	MobileTransfers,
	DesktopTransfers,
	TableRowContainer,
	HeadersContainer,
	HeadersRow,
	TableHeaders,
	PageHeader,
	Row,
	WelcomeDialogContent,
	VQDialogContent,
	VQContainer,
	OnboardingSetup
}
