import styled from "@emotion/styled"

import theme from "../index";

const Heading1 = styled.h1`
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: ${theme.colors.base.graphite};
`

const Heading2 = styled.h2`
  font-size: 30px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: ${theme.colors.base.graphite};
`

const Heading3 = styled.h3`
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: ${theme.colors.base.graphite};
`

const Heading4 = styled.h4`
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: ${theme.colors.base.graphite};
`

const Heading5 = styled.h5`
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: ${theme.colors.base.graphite};
  `

const Subheading1 = styled.h5`
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 1.2px;
  color: ${theme.colors.greys.grey5};
`

const Subheading2 = styled.h6`
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  color: ${theme.colors.greys.grey5};
`

const DatatableTitle = styled.h6`
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.4px;
  color: ${theme.colors.greys.grey5};
`

const Text1 = styled.p`
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.colors.base.graphite};
`

const Text2 = styled.p`
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${props => theme.colors.greys[props.color] || theme.colors.base.graphite};
`;

const Text3 = styled.p`
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${props => theme.colors.greys[props.color] || theme.colors.base.graphite};
  margin: ${props => props.margin || 0};
`;

const ErrorMessage = styled.p`
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  margin-top: 8px;
  color: ${theme.colors.accent.red};
`;

const TextInfo = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
  color: ${theme.colors.greys.grey5};
`;

const TextSuccess = styled.p`
  font-size: 28px;
  line-height: 1;
  font-weight: 700;
  color: ${theme.colors.cta.green};
`;

const dividerSize = {
  sm: "16px",
  md: "24px",
  lg: "32px",
};

const Divider = styled.hr`
  background-color: transparent;
  border: 1px ${props => props.dashed ? `dashed ${props.color || "#ebebeb"}` : `solid ${props.color || "#ebebeb"}`};
  margin-top: ${props => dividerSize[props.size]};
  margin-bottom: ${props => dividerSize[props.size]};
`;

export {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Subheading1,
  Subheading2,
  Text1,
  Text2,
  Text3,
  ErrorMessage,
  TextInfo,
  Divider,
  TextSuccess,
  DatatableTitle
}
