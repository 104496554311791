/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import planeWhiteCircle from "assets/images/landing-pages/planeWhiteCircle";
import languageWhiteCircle from "assets/images/landing-pages/languageWhiteCircle";
import defaultBgImageMobile from "assets/images/landing-pages/defaultHeroMobile.jpg";
import defaultBgImageDesktop from "assets/images/landing-pages/defaultHeroDesktop.jpg";
import phoneWhiteCircle from "assets/images/landing-pages/phoneWhiteCircle";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FlexContainer, Container } from "./styledComponents";
import { GonProvider } from "../../helpers/GonProviders";
import WidgetContainer from "../../components/landing-pages/WidgetContainer";
import { isTruthy } from "../../helpers/partners/is";

const {
  current_property: { name, main_bg_image: backgroundPhoto, bg_image_opacity: bgImageOpacity },
  translations: { customer_support, flight_monitoring_feature, trained_english_speakers },
  preview_mode
} = GonProvider;

const isPreview = isTruthy(preview_mode);

const backgroundOpacity = {
  dark: "linear-gradient(to bottom,rgba(22,35,54,0.7),rgba(22,35,54,0.95))",
  medium: "linear-gradient(to bottom,rgba(22,35,54,0.5),rgba(22,35,54,0.65))",
  light: "linear-gradient(to bottom,rgba(22,35,54,0.4),rgba(22,35,54,0.45))",
};

const HeaderContainer = styled(FlexContainer)`
  background-image: ${bgImageOpacity
    ? backgroundOpacity[bgImageOpacity]
    : "linear-gradient(to bottom,rgba(22,35,54,0.5),rgba(22,35,54,0.65))"};
  background-size: cover;
  background-position: center;
  padding: 90px 0;
  height: ${window.innerHeight > 700 || isPreview ? "740px" : "100vh"};
  position: relative;

  @media (max-width: 1024px) {
    font-size: 30px;
    padding: 90px 0px;
    position: initial;
  }
  @media (max-width: 991px) {
    padding-top: 20px;
    text-align: center;
    align-items: flex-start;
    min-height: 200px;
    height: auto;
  }
  @media (max-width: 768px) {
    padding: 0px 0 0 0px;
    text-align: center;

    height: auto;
    align-items: center;
    min-height: 200px;
  }
`;

const ImageContainer = styled.div`
  background-image: ${backgroundPhoto !== null ? `url(${backgroundPhoto})` : `url(${defaultBgImageDesktop})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: ${backgroundPhoto !== null ? "center" : "initial"};
  height: ${window.innerHeight > 700 || isPreview ? "740px" : "100vh"};

  @media (max-width: 991px) {
    height: auto;
    background-image: ${backgroundPhoto !== null ? `url(${backgroundPhoto})` : `url(${defaultBgImageMobile})`};
  }
`;

const Title = styled.h1`
  color: #ffffff;
  font-size: ${name.length < 50 ? "54px" : "30px"};
  line-height: ${name.length < 50 ? "64px" : "40px"};
  font-weight: 700;
  margin-top: 240px;
  max-width: 900px;
  margin-bottom: 20px;

  @media (max-width: 1460px) {
    max-width: 720px;
  }

  @media (max-width: 1300px) {
    max-width: 460px;
  }
  @media (max-width: 991px) {
    margin: auto;

    font-size: ${name.length < 25 ? "30px" : "20px"};
    line-height: ${name.length < 25 ? "40px" : "30px"};
    text-align: center;
    padding: 16px 0 38px;
  }
`;

const Subtitle = styled.p`
  color: #ffffff;
  font-size: 20px;
  line-height: 28px;
  max-width: 760px;

  @media (max-width: 1400px) {
    max-width: 460px;
  }

  @media (max-width: 991px) {
    display: none;
  }
`;
const HeadContainer = styled(Container)`
  @media (max-width: 991px) {
    padding: 0 20px;
    margin: 0 auto;
  }
`;
const Icon = styled(LazyLoadImage)`
  height: 54px;
  margin-bottom: 20px;
`;

const FeatureText = styled.p`
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
`;

const Feature = styled.div`
  max-width: 145px;
  margin-right: 35px;
  margin-top: 45px;

  @media (max-width: 991px) {
    display: none;
  }
`;

const BNBName = styled.p`
  font-size: 22px;
  color: #fff;
  font-family: MuseoSans;
  line-height: 28px;
  max-width: 460px;
  @media (max-width: 991px) {
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    line-height: 25px;
  }
`;
const InnerContent = styled.div`
  width: calc(100% - 400px);
  padding-bottom: 80px;
  @media (max-width: 991px) {
    width: 100%;
    padding-bottom: 0;
  }
`;

const HeaderSection = ({ title, subtitle, type, bnbPropertyName = null }) => {
  const features = [
    {
      icon: languageWhiteCircle,
      text: trained_english_speakers,
    },
    {
      icon: phoneWhiteCircle,
      text: customer_support,
    },
    {
      icon: planeWhiteCircle,
      text: flight_monitoring_feature,
    },
  ];

  return (
    <ImageContainer>
      <HeaderContainer type={type} align="flex-end">
        <HeadContainer>
          <InnerContent>
            <Title dangerouslySetInnerHTML={{ __html: title }} />
            {bnbPropertyName && <BNBName>{bnbPropertyName}</BNBName>}
            {type === "hotel" && <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />}
            <FlexContainer>
              {features.map(({ icon, text }) => (
                <Feature key={text}>
                  <Icon alt={text} src={icon} />
                  <FeatureText>{text}</FeatureText>
                </Feature>
              ))}
            </FlexContainer>
          </InnerContent>
          {window.innerWidth > 991 && <WidgetContainer type={type} />}
        </HeadContainer>
      </HeaderContainer>
    </ImageContainer>
  );
};

export default HeaderSection;
