/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import greyArrowDown from "assets/images/landing-pages/greyArrowDown";
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Title, GreenSeparator, WhiteRows, FlexContainer, Container, LeftColumn } from "./styledComponents";
import { Text1 } from "../../themes/typography";
import useComponentVisible from "../common/useComponentVisible";
import { GonProvider } from "../../helpers/GonProviders";

const { current_property: { faqs }, translations: { faqs_title } } = GonProvider;

const ContainerFaq = styled.div`
    background-color:rgba(45,59,78,0.03);
    padding: 110px 0 100px ;
`;

const FaqTitle = styled(Text1)`
    font-size: 16px;
    font-weight: 700;
`;

const Text = styled(Text1)`
    font-size: 14px;
    font-weight: 500;
    padding: 15px 10px 0;
`;

const Boxes = styled(WhiteRows)`
    padding: 24px 21px;
`;

const FAQs = () => {
    const [openFaq, setOpenFaq] = useState(false);
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const showFaq = (i) => {
        setOpenFaq(i);
        setIsComponentVisible(!isComponentVisible);
        
        // if another faq is clicked
        if(i !== openFaq) {
            setIsComponentVisible(true);
        }
    }

    return (
        <ContainerFaq ref={ref}>
            <Container>
                <LeftColumn>
                    <Title>{faqs_title}</Title>
                    <GreenSeparator />
                    {faqs.map(({ title, content }, i) => (
                        <Boxes key={title} onClick={() => showFaq(i)} css={{ cursor: "pointer" }}>
                            <FlexContainer justify="space-between">
                                <FaqTitle >{title}</FaqTitle>
                                <LazyLoadImage loading="lazy" alt="arrow" src={greyArrowDown} css={{ transform: openFaq === i && isComponentVisible ? "rotate(180deg)" : "none" }} onClick={() => showFaq(i)}/>
                            </FlexContainer>
                            {isComponentVisible && openFaq===i &&<Text >{content}</Text>}
                        </Boxes>
                    ))}
                </LeftColumn>
            </Container>
        </ContainerFaq>
    )
}

export default FAQs;
