/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import transfersbyWelcomePickupsLogo from "assets/images/landing-pages/transfersbyWelcomePickupsLogo";
import { FlexContainer } from "../../partners-dashboard/shared/styled-components";
import { Heading4 } from "../../../themes/typography";
import { TransfersTitle } from "../../landing-pages/styledComponents";
import { GonProvider } from "../../../helpers/GonProviders";

const { translations: { help, privacy_policy, terms_of_use, transfers }, current_property: { logo, name } } = GonProvider;

const Container = styled(FlexContainer)`
    padding: 70px 90px;
    background-color: #fff;
    font-size:14px;

    @media (max-width:768px){
        display:block !important;
    }
`;

const Logo = styled.img`
    height: 60px;

    @media (max-width:768px){
        max-width: 100px;
        height:auto;
    }
`;

const Link = styled.a`
    display:block;
    color: #2D3B4E;
    font-size: 14px;
    line-height: 18px;
    padding: 0 10px;

    :hover, :focus {
        color: #2D3B4E;
    }

    @media (max-width:768px){
        text-align:center;
        margin-bottom: 20px;
    }
`;

const WelcomeLogo = styled(Link)`
    @media (max-width:768px){
        margin-top: 40px;
    }
`;

const PropertyLogo = styled(FlexContainer)`
    @media (max-width:768px){
        justify-cintent: center !important;
        margin-bottom: 30px;
    }
`;

const FooterColumns = styled(FlexContainer)`
    @media (max-width:768px){
        display:block !important;
        margin-bottom: 30px;
    }
`;

const LandingPageFooter = () => (
    <Container justify="space-between">
        <PropertyLogo align="center" justify="center">
            {logo ? <Logo alt="Welcome Pickups Logo" src={logo} />
                : <Heading4>{name}</Heading4>
            }
            <TransfersTitle> <span/> {transfers}</TransfersTitle>
        </PropertyLogo>
        <FooterColumns justify="space-between" align="center">
            <Link href="https://www.welcomepickups.com/terms-of-use/" target="_blank">{terms_of_use}</Link>
            <Link href="https://www.welcomepickups.com/terms-of-use/" target="_blank">{privacy_policy}</Link>
            <Link href="https://support.welcomepickups.com/en/" target="_blank">{help}</Link>
            <WelcomeLogo mobileHide="true"><img alt="welcome logo" src={transfersbyWelcomePickupsLogo} /></WelcomeLogo>
        </FooterColumns>
    </Container>
)


export default LandingPageFooter;
