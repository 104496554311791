/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import greenStar from "assets/images/landing-pages/greenStar";
import greyStar from "assets/images/landing-pages/greyStar";
import greenArrowDown from "assets/images/landing-pages/greenArrowDown";
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { WhiteRows, Title, GreenSeparator, FlexContainer, LeftColumn, Container } from "./styledComponents";
import { Text1, Heading4 } from "../../themes/typography";
import { GonProvider } from "../../helpers/GonProviders";

const { current_property: { reviews }, translations: { reviews_title, today, one_day_ago, n_days_ago, n_weeks_ago } } = GonProvider;
const REVIEWS_COUNT = 2;

/**
 * @param rawText
 * @param words
 */
function replaceTranslatedProperties(rawText, ...words) {

    const specialCharactersRegEx = /%{[_a-z0-9]*}/;
  
    return rawText ? words.reduce((text, word) => {
      return text.replace(specialCharactersRegEx, word);
    }, rawText) : "";
  }

/**
 * @param stars
 */
function getStars(stars) {
    const greenStars = Array.from({ length: stars }, (_, index) => <Star key={index} alt="star" src={greenStar} />);
    let greyStars = [];

    if (stars < 5) {
        greyStars = Array.from({ length: 5 - stars }, (_, index) => <Star key={index} alt="star" src={greyStar} />);
    }

    return greenStars.concat(greyStars);
}

const Star = styled(LazyLoadImage)`
    margin: 0 2px;
`;

const Text = styled(Text1)`
    font-size: 16px;
    font-weight: 500;
    margin-top: 30px;
`;

const MoreButton = styled.button`
    height: 49px;
    width: 209px;
    border: 1px solid #48D9A4;
    border-radius: 4px;
    color: #48D9A4;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    font-weight:700;
    display: block;
    margin: 0 auto;
`;

const ReviewName = styled(Heading4)`
    font-size: 20px;
    margin-right: 10px;

    @media (max-width: 768px){
        font-size: 18px;
    }
`;

const StarsContainer = styled(FlexContainer)`
    img {
        width:17px;
    }

    @media (max-width: 768px){
        width: 85px;
        img {
            width:14px;
        }
    }
`;

const DateText = styled.p`
    color: rgba(45,59,78,0.5);
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;
`;

const NameAndDate = styled(FlexContainer)`
    text-align: left;

    @media (max-width: 1200px){
        display: block !important;
    }

    @media (max-width: 768px){
        display: block !important;
    }
`;

/**
 * @param date
 */
function getDate(date) {
    const filteredDate = new Date(date)
    const todayDate = new Date();
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;

    // Discard the time and time-zone information.
    const utc1 = Date.UTC(filteredDate.getFullYear(), filteredDate.getMonth(), filteredDate.getDate());
    const utc2 = Date.UTC(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate());

    const diff = Math.floor((utc2 - utc1) / _MS_PER_DAY);

    if(diff === 0){
        return today;
    }

    if(diff=== 1) {
       return one_day_ago;
    }

    if(diff < 31) {

        return replaceTranslatedProperties(n_days_ago, diff);
    }

    return replaceTranslatedProperties(n_weeks_ago, Math.floor(diff/7));
}

const Reviews = () => {
    const [more, setMore] = useState(REVIEWS_COUNT);

    const showMore = () => {
        setMore(4)
    }

    return (
        <Container>
            <LeftColumn>
                <Title>{reviews_title.split(" ").map(item=>item.substring(0,1).toUpperCase()+item.substring(1)).join(" ")}</Title>
                <GreenSeparator />
                <div>
                    {reviews.map(({ full_name: name, checkin_date: date, rating, comment }, i) => (
                        <WhiteRows key={name} width="100%" css={{ display: i + 1 > more && "none" }}>
                            <FlexContainer align="start" justify="space-between">
                                <NameAndDate justify="start">
                                    <ReviewName>{name}</ReviewName>
                                    <DateText>{getDate(date)}</DateText>
                                </NameAndDate>
                                <StarsContainer justify="start">{getStars(rating)}</StarsContainer>
                            </FlexContainer>
                            <Text>{comment}</Text>
                        </WhiteRows>
                    ))}
                </div>
                {(more < 4) || (reviews.length === 2) && <MoreButton onClick={() => showMore()}>See two more <LazyLoadImage loading="lazy" alt="arrow down" src={greenArrowDown} /></MoreButton>}
            </LeftColumn>
        </Container>
    )
}


export default Reviews;
