/**
 * @param object
 */
function deepFreeze(object) {
    // Retrieve the property names defined on object
    const propNames = Object.getOwnPropertyNames(object);
    for (const name of propNames) {
        const value = object[name];
        object[name] = value && typeof value === "object" ? deepFreeze(value) : value;
    }
    return Object.freeze(object);
}

const deepCopyObject = (obj) => {
    if (obj == null || typeof obj === "undefined") {
        return {};
    }
    const copy = JSON.parse(JSON.stringify(obj));
    if (!copy) {
        return {};
    }

    return deepFreeze(copy);
};

const GonProvider = deepCopyObject(window.gon);

export {
    GonProvider
}