/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { useState, useEffect, useRef } from "react";
import transfersbyWelcomePickupsLogo from "assets/images/landing-pages/transfersbyWelcomePickupsLogo";
import graphiteArrowDown from "assets/images/landing-pages/graphiteArrowDown";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Heading4 } from "../../themes/typography";
import { changeLanguage, getCurrentLanguage, languages, getFlag } from "./helpers";
import useComponentVisible from "../common/useComponentVisible";
import { TransfersTitle, TransparentLink, FlexContainer, Container } from "./styledComponents";
import { GonProvider } from "../../helpers/GonProviders";

const { translations : { transfers, book_a_transfer:bookATransfer }, current_property: { organization_name: organizationName,logo, name, hide_lp_powered_by_header, branding_theme: { main_color: mainColor } } } = GonProvider;

const MenuContainer = styled(FlexContainer)`
    height: 80px;
    width: 100%;
    background-color: #FFFFFF;
    padding: 0 0px;
    z-index: 200;
    @media (max-width: 1200px){
      padding: 0 35px;
  }
    @media (max-width: 768px){
        padding: 0 20px;
        height: 60px;
        z-index: 10;
    }
`;
const HeaderRow=styled(Container)`
padding-top:0;
position:relative;
z-index: 101;
@media (max-width: 1200px){
padding:0;
margin:0;
max-width:100%;
}
@media(max-width:767px){
  z-index:9;
}
`;
const PropertyLogo = styled(LazyLoadImage)`
    height: 60px;

    @media (max-width: 768px){
        height: auto;
        max-width: 100px;
    }
`;

const LanguageButton = styled.div`
    cursor:pointer;
    padding: 15px;
    margin: 0 10px;
    @media (max-width: 768px){
        margin: 0;
        padding: 0;
    }

    img {
        padding: 4px;
    }
`;

const ScrollToWidgetButton = styled.a(({ show }) => (`
    height: 56px;
    width: calc(100% - 40px);
    left:20px;
    right:20px;
    border-radius: 4px;
    background-color: ${mainColor || "#48D9A4"};
    display: none;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 55px;
    position: fixed;
    bottom: 10px;
    text-align:center;
    z-index:1000;

    :focus, :hover {
        color: #FFFFFF;
    }

    @media (max-width: 768px){
        display: ${show ? "block" : "none"};
    }
`));

const LanguagesDropdown = styled.ul`
    background: #fff;
    list-style-type:none;
    position: absolute;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 5px 20px 0 rgba(45,59,78,0.09);
    top: 57px;
    z-index: 101;

    li {
        padding: 5px;
        cursor:pointer;
    }
`;

const StaticMenu = ({ type="normal" }) => {
    const [scroll, setScroll] = useState(false);
    const [currentLanguage] = useState(getFlag(getCurrentLanguage()));
    const englishLanguage = getFlag("en");
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const mobileRef = useRef(null);
    const defaultName = type==="bnbProperty" ? organizationName : name;

    const listenToScroll = () => {
        if (window.pageYOffset >= 600) {
            setScroll(true)
        }
        else {
            setScroll(false)
        }
    }

    useEffect(() => {
        document.addEventListener("scroll", listenToScroll, true);
        return () => {
            document.removeEventListener("scroll", listenToScroll, true);
        };
    });


    return ( <HeaderRow>
        <MenuContainer justify="space-between" align="center" ref={mobileRef}>

            <FlexContainer align="center" justify="flex-start">
                {logo !== null
                    ? <PropertyLogo alt="property logo" src={logo} />
                    : <Heading4>{defaultName}</Heading4>
                }
                <TransfersTitle> <span/> {transfers}</TransfersTitle>
            </FlexContainer>
            <FlexContainer align="center" ref={ref}>
                <LanguageButton onClick={() => setIsComponentVisible(true)} mobileHide={scroll}>
                 <LazyLoadImage loading="lazy" alt="language" src={typeof currentLanguage=="undefined" ? englishLanguage.flag : currentLanguage.flag} />
                    <LazyLoadImage loading="lazy" alt="arrow" src={graphiteArrowDown}/>
                </LanguageButton>
                {isComponentVisible && <LanguagesDropdown>
                    {languages.map(({ code, flag }) => (
                        <li key={code} onClick={(e) => changeLanguage(code)} onKeyPress={(e) => changeLanguage(code)}>
                            <LazyLoadImage alt={code} src={flag} />
                        </li>
                    ))}
                </LanguagesDropdown>}
                {!hide_lp_powered_by_header &&
                    <TransparentLink mobileHide><LazyLoadImage loading="lazy" alt="welcome logo" src={transfersbyWelcomePickupsLogo} /></TransparentLink>
                }
            </FlexContainer>
            <ScrollToWidgetButton href="#welcomepickups" show={scroll}>{bookATransfer}</ScrollToWidgetButton>

        </MenuContainer>   </HeaderRow>
    )

}

export default StaticMenu;
