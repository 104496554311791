/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import greenNoteIcon from "assets/images/landing-pages/greenNoteIcon";
import { WhiteRows,Container } from "../landing-pages/styledComponents";
import { FlexContainer } from "../partners-dashboard/shared/styled-components";
import { Heading4, Text2 } from "../../themes/typography";
import { GonProvider } from "../../helpers/GonProviders";

const NoteContainer = styled(WhiteRows)`
    margin-top: 57px;
    margin-left: 20px;
    max-width: 492px;

    @media (max-width: 768px){
        width: 90%;
        margin:0 auto;
        position:relative;
    }
`;

const IconBox = styled(FlexContainer)`
    max-width: 64px;
    background-color: rgba(72,217,164,0.1);
    border-radius: 50px;
    width: 64px;
    height: 64px;
    margin-bottom: 10px;
`;

const TextContainer = styled.div`
    width: 80%;
    margin-left: 25px;
`;

const NoteTitle = styled(Heading4)`
    margin-bottom: 10px;
    font-size: 18px;
`;

const { current_property: { note_from_host: note }, translations: { note_from_host_default: noteDefault } } = GonProvider;



const NoteFromHost = () => {
    const finalNote = note || noteDefault;

    return (
      <Container>
        <NoteContainer>
            <FlexContainer>
                <IconBox justify="center">
                    <img alt="notes" src={greenNoteIcon} />
                </IconBox>
                <TextContainer>
                    <NoteTitle css={{ marginBottom: "10px" }}>Note from the Host</NoteTitle>
                    <Text2 dangerouslySetInnerHTML={{ __html: `"${finalNote}"` }} />
                </TextContainer>
            </FlexContainer>
        </NoteContainer>
        </Container>
    )
}

export default NoteFromHost;
