import { createMuiTheme } from "@material-ui/core/styles";
import baseTheme from "./index";

// https://mui.com/material-ui/customization/palette/
const palette = {
  primary: {
    main: "#00cf95",
    dark: "#00C58A",
    darker: "#007049",
    light: "#D7F7E9",
    lighter: "rgba(215, 247, 233, 0.5)",
    contrastText: "#fff"
  },
  secondary: {
    main: "#2D3B4E",
    dark: "#1F2836",
    light: "#425269"
  },
  grey: {
    grey75: "rgba(45, 59, 78, .75)",
    grey6: "rgba(45, 59, 78, .6)",
    grey5: "rgba(45, 59, 78, .5)",
    grey4: "rgba(45, 59, 78, .4)",
    grey3: "rgba(45, 59, 78, .3)",
    grey2: "rgba(45, 59, 78, .2)",
    grey15: "rgba(45, 59, 78, .15)",
    grey1: "rgba(45, 59, 78, .1)",
    grey05: "rgba(45, 59, 78, .05)",
    grey06: "rgba(45, 59, 78, .06)"
  },
  base: {
    graphite: "#2d3b4e",
    graphiteLight: "#616C7A",
    graphiteLighter: "#969DA6",
    graphiteDark: "#0f161e",
    graphiteDarker: "#27282E",
    white: "#fff",
    white75: "rgba(255, 255, 255, .75)",
    white5: "rgba(255, 255, 255, .5)",
  },
  accent: {
    red: "#F05B56",
    green: "#00CF95",
    yellow: "#F8C300",
    blue: "#3070D4",
  },
  background: {
    white: "#ffffff",
    grey: "#f5f5f5",
  },
}

// https://mui.com/material-ui/customization/breakpoints/
const breakpoints = {
  values: {
    xs: 0,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1920
  }
}

/**
 * A variant of the main theme to be used in the partners dashboard
 */
const partnersDashboardTheme = createMuiTheme({
  ...baseTheme,
  palette,
  breakpoints,
  drawer: {
    desktopWidth: "192px",
    mobileWidth: "300px",
    collapsedWidth: "51px"
  },
  navbar: {
    desktopHeight: "70px",
    mobileHeight: "60px",
  },
  // https://mui.com/material-ui/react-typography/
  typography: {
    allVariants: {
      fontFamily: "MuseoSans",
    },
    h1: {
      fontSize: 24,
      fontWeight: 600,
      color: palette.secondary.light,
      lineHeight: "31.2px",

      [`@media (min-width:${breakpoints.values.sm}px)`]: {
        fontSize: 36,
        lineHeight: "unset",
      }
    },
    h2: {
      fontSize: 24,
      fontWeight: 600,
      color: palette.secondary.light,
      lineHeight: "31.2px",

      [`@media (min-width:${breakpoints.values.sm}px)`]: {
        fontSize: 30,
        lineHeight: "39px"
      }
    },
    h3: {
      fontSize: 24,
      fontWeight: 600,
      color: palette.secondary.light
    },
    h4: {
      fontSize: 20,
      fontWeight: 600,
      color: palette.secondary.light
    },
    h5: {
      fontSize: 14,
      fontWeight: 600,

      [`@media (min-width:${breakpoints.values.sm}px)`]: {
        fontSize: 18,
      }
    },
    h6: {
      fontSize: 14,
      fontWeight: 600,

      [`@media (min-width:${breakpoints.values.sm}px)`]: {
        fontSize: 16,
      }
    },
    body1: {
      fontSize: 14,
      color: palette.base.graphite,
      lineHeight: "18px"
    },
    body2: {
      fontSize: 12,
      color: palette.base.graphite,
      lineHeight: "18px"
    },
    subtitle1: {
      fontSize: 12,
      fontWeight: 400
    },
    subtitle2: {
      fontSize: 13,
      fontWeight: 600
    }
  },
  spaces: {
    desktop: {
      pageTopSpace: "24px",
      pageRightSpace: "36px",
      pageBottomSpace: "36px",
      pageLeftSpace: "36px",
    },
    mobile: {
      pageTopSpace: "24px",
      pageRightSpace: "16px",
      pageBottomSpace: "36px",
      pageLeftSpace: "16px",
    }
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
          fontSize: "12px"
      }
    }
  }
});

export default partnersDashboardTheme;