import styled from "@emotion/styled";

const WhiteSquares = styled.div(({ bgGradient = "#FFFFFF", minHeight="266px" }) => (`
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 5px 20px 0 rgba(45,59,78,0.06);
    position:relative;
    padding: 30px 35px 0 35px;
    margin-bottom: 30px;
    background-image: linear-gradient(to bottom, ${bgGradient});
    width: 47%;
    max-width: 900px;
    min-height: ${minHeight};

    @media (max-width: 1400px){
        max-width: 600px;
    }

    @media (max-width: 1024px){
        width: 47%;
    }

    @media (max-width: 580px){
        width: 100%;
    }
`));

const WhiteRows = styled.div`
    width: 100%;
    background-color: #fff;
    box-shadow: 0 5px 20px 0 rgba(45,59,78,0.06);
    border: 1px solid rgba(45,59,78,0.14);
    border-radius: 6px;
    position:relative;
    padding: 40px 35px;
    margin-bottom: 30px;
    max-width: 900px;
`;

const Title = styled.h1`
    color: #2D3B4E;
    font-size: 30px;
    line-height: 46px;
    font-weight:700;

    @media (max-width: 768px){
        text-align: center;
        font-size: 26px;
        line-height: 35px;
    }
`;

const GreenSeparator = styled.div`
    border-bottom: 3px solid #48D9A4;
    width: 38px;
    margin-bottom: 50px;

    @media (max-width: 768px){
        margin: 0px auto 50px auto;
    }
`;

const TransfersTitle = styled.p`
    color: #2D3B4E;
    font-size: 21px;
    letter-spacing: 0.4px;
    padding: 0 5px;
    text-transform: uppercase;

    span {
        margin: 0 15px;
        border-left: 1px solid rgba(45,59,78,0.2);
    }

    @media (max-width: 768px){
        font-size: 15px;
    }
`;

const TransparentLink = styled.a(({ mobileHide }) => (`
    color: #2D3B4E;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    padding: 0 20px;

    @media (max-width: 768px){
        display: ${mobileHide ? "none" : "block"};
    }
`));

const FlexContainer = styled.div(({ align="normal", justify="initial", margin="initial",type=""}) => (`
	display: flex;
	align-items: ${align};
	justify-content: ${justify};
	margin: ${margin};

`));



const Container = styled.div`
    padding: 100px 0 0;
    max-width: 1125px;
    width:100%;
    margin: 0 auto;
    position:relative;

    @media(max-width:1200px){
      max-width:1024px;
      padding-left:35px;
      padding-right:35px;
    }
    @media(max-width:1024px){
      max-width:991px;

    }
    @media (max-width: 991px) {
      max-width: 768px;
      padding: 100px 20px 0 20px;
  }
    @media (max-width: 767px) {
        width: 100%;
        padding: 80px 15px 0;
        margin-bottom:44px;
    }
`;

const LeftColumn = styled.div`
    width: calc(100% - 400px);

    @media (max-width: 991px) {
        width: 100%;
    }
`;

export {
    WhiteSquares,
    WhiteRows,
    Title,
    GreenSeparator,
    TransfersTitle,
    TransparentLink,
    FlexContainer,
    Container,
    LeftColumn
}
