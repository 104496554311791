import "core-js/stable";
import React from "react";
import ReactDOM from "react-dom";
import { AppContainer } from "react-hot-loader";
import BnbOrganizationLandingPage from "../../apps/bnb-organization-landing-page";

const render = Component => {
	ReactDOM.render(
		<AppContainer>
			<Component />
		</AppContainer>,
		document.getElementById("bnb-landing-page")
	)
};

document.addEventListener("DOMContentLoaded", () => {
	render(BnbOrganizationLandingPage);
});
