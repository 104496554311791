import React from "react";
import { ThemeProvider } from "emotion-theming";
import theme from "../../themes";
import StaticMenu from "../../components/landing-pages/StaticMenu";
import WidgetContainer from "../../components/landing-pages/WidgetContainer";
import HeaderSection from "../../components/landing-pages/HeaderSection";
import Reviews from "../../components/landing-pages/Reviews";
import FAQs from "../../components/landing-pages/FAQs";
import LandingPageFooter from "../../components/common/LandingPageFooter";
import MoreBenefits from "../../components/landing-pages/MoreBenefits";
import NoteFromHost from "../../components/bnb-landing-pages/NoteFromHost";
import HowItWorks from "../../components/landing-pages/HowItWorks";
import { GonProvider } from "../../helpers/GonProviders";
import { replaceTranslatedProperties } from "../../components/landing-pages/helpers";

const bnbOrganizationLandingPage = function BnbOrganizationLandingPage() {
    const { current_property: { name }, translations: { title } } = GonProvider;

    return (
        <ThemeProvider theme={theme}>
            <StaticMenu />
            <HeaderSection type="bnbOrg" title={replaceTranslatedProperties(title, name)}/>
            {window.innerWidth <= 991 && <WidgetContainer type="bnbOrg"/> }

            <NoteFromHost />
            <HowItWorks />
            <Reviews />
            <MoreBenefits />
            <FAQs />
            <LandingPageFooter />
        </ThemeProvider>
    )
}

export default bnbOrganizationLandingPage;
