/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import lessThan60Image from "assets/images/landing-pages/lessThan60Image.jpg";
import driverDetailsImage from "assets/images/landing-pages/driverDetailsImage.jpg";
import driverSignImage from "assets/images/landing-pages/driverSignImage.jpg";
import enjoyRideImage from "assets/images/landing-pages/enjoyRideImage.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Text1 } from "../../themes/typography";
import { WhiteSquares, Title, GreenSeparator, FlexContainer, Container, LeftColumn } from "./styledComponents";
import { GonProvider } from "../../helpers/GonProviders";

const { translations: { less_than_60, driver_details, enjoy_the_ride, driver_meet_greet, how_it_works_title } } = GonProvider;

const features = [
    {
        icon: lessThan60Image,
        text: less_than_60
    },
    {
        icon: driverDetailsImage,
        text: driver_details
    },
    {
        icon: driverSignImage,
        text: driver_meet_greet
    },
    {
        icon: enjoyRideImage,
        text: enjoy_the_ride
    }
]

const FeaturesContainer = styled(FlexContainer)`
    flex-wrap: wrap;

    @media (max-width: 580px){
        display:block;
    }
`;

const Image = styled(LazyLoadImage)`
    max-width: 100%;
    border-radius: 8px 8px 0 0;
`;

const Text = styled(Text1)`
    font-size: 16px;
    font-weight: 500;
`;

const NumberWhiteBox = styled.div`
    height: 28px;
    width: 37px;
    border-radius: 8px 0 8px 0;
    background-color: #FFFFFF;
    box-shadow: 0 2px 9px -4px rgba(0,0,0,0.1);
    color: #00CF95;;
    font-size: 14px;
    line-height: 20px;
    position: absolute;
    left: 8px;
    top: 8px;
    text-align: center;
    font-weight: 700;
    padding: 4px;
`;

const FeaturesTextContainer = styled(FlexContainer)`
    width: 100%;
    height: 130px;
    padding: 0 10px;
`;

const HowItWorks = () => (
    <Container>
        <LeftColumn>
            <Title>{how_it_works_title.split(" ").map(item=>item.substring(0,1).toUpperCase()+item.substring(1)).join(" ")}</Title>
            <GreenSeparator />
            <FeaturesContainer justify="flex-start" align="center">
                {features.map(({ icon, text }, i) => (
                    <WhiteSquares key={text} css={{ padding: 0,
                                                marginRight: i%2===0 && "30px" }} minHeight="304px">
                        <NumberWhiteBox>{i+1}</NumberWhiteBox>
                        <Image loading="lazy" src={icon} alt={text}/>
                        <FeaturesTextContainer align="center" justify="center">
                            <Text>{text}</Text>
                        </FeaturesTextContainer>
                    </WhiteSquares>
                ))}
            </FeaturesContainer>
        </LeftColumn>
    </Container>
)


export default HowItWorks;
